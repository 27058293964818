import {Box, Typography} from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';

export default function FotoGallery() {


    return (
        <Box sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundColor: '#b5267a',
            margin: 0,
            padding: 0
        }}>
            <Box sx={{display:'flex', flexDirection:"row", justifyContent:"flex-start", width:'100%', height:'10vh', alignItems:'center'}}>
                <Link to="/" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center', color: 'inherit' }}>
                    <HomeIcon sx={{ color: '#fff', marginLeft: '1rem', fontSize: '35px' }} />
                    <Typography variant={'h5'} color={'#fff'} sx={{ textDecoration: 'underline', marginLeft: '0.5rem' }}>
                        Home
                    </Typography>
                </Link>
            </Box>
            <iframe id="MemzoInt" src="https://memzo.ai/h/yajw03fe/14441/20697"
                    allow="camera *"
                    style={{border:'none',width:'100vw',height:'85vh',overflowY: 'hidden',overflow:'hidden'}}
                    title="Get Your Photos">
            </iframe>

        </Box>
    );
}