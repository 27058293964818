import React, {useEffect, useState} from "react";
import {Dialog, Fade, Slide} from "@mui/material";
import QuestionDialogContent from "./QuestionDialogContent";
import {firestore} from "../../firebase/clientApp";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const TransitionColor = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} timeout={2000} />;
});

export default function Question({idPlaying,url, user, event, theme}){

    const [open, setOpen] = useState(false)

    useEffect(()=> {
        const handleNewId = async ()=> {
            handleClose()
            setTimeout(() => {
                idPlaying === '' ? handleClose() : handleClickOpen()
            },300)
        }
        handleNewId().catch(console.error)
    },[idPlaying])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [elementPlaying, setElementPlaying] = useState(null)

    useEffect(() => {
        idPlaying && getElementPlaying()
    }, [idPlaying])

    function getElementPlaying() {
        firestore.collection(url+'/queue').doc(idPlaying).onSnapshot(s => {
            setElementPlaying({...s.data(), id: s.id})
            return s.data()
        })
    }

    return (
        <Dialog fullScreen open={open} hideBackdrop={true} disableEscapeKeyDown={true}
                TransitionComponent={(elementPlaying?.tipo === 'color' && !!elementPlaying?.colorTransitionDuration && elementPlaying?.colorTransitionDuration !== '0')
                    ? TransitionColor : Transition}
                onClose={handleClose}
        >
            {open && elementPlaying &&
                <QuestionDialogContent elementPlaying={elementPlaying} idPlaying={idPlaying} url={url} user={user} theme={theme}/>}
        </Dialog>
    )
}
