import React, {useEffect, useState} from "react";
import Applausometro from "./Applausometro";
import Question from "./Questions/Question";
import {useNavigate, useParams} from "react-router-dom";
import {firestore} from "../firebase/clientApp";
import {Box, Button, CircularProgress, Grow, Stack} from "@mui/material";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {DisplayName} from "./DisplayName";
import {PinnedQuestions} from "./PinnedQuestions";
import CountdownTimerWrapper from "./CountdownTimer";
import questionType from "../assets/data/typeMap";
import pleshDark from "../theme/pleshDark";

const LogoBox = ({logo}) => {
    return(
        <Box style={{width:'100%', height:'30%'}} sx={{px: 2}}>
            <img style={{ margin:'auto', width:'80%', height:'100%', objectFit:'contain'}} src={logo}/>
        </Box>
    )
}

export default function VoteContent({user, event, loading, userData, setUserData}) {
    const navigate = useNavigate();

    const {id} = useParams()
    const [idPlaying, setIdPlaying] = useState('')
    const [eventPlaying, setEventPlaying] = useState('')
    const [pinned, pinnedLoading, pinnedError] = useCollection(firestore.collection('users/plesh/events/' + id + '/queue').where("pinned", "==",true), {})
    const url = 'users/plesh/events/'
    const [theme, themeLoading, themeError] = useDocument(firestore.collection('users/plesh/events/' + id + '/themes').doc(event?.data().theme))

    useEffect(() => {
        getIdPlaying()
    }, [])

    useEffect(() => {

    }, [userData]);

    function getIdPlaying() {
        firestore.collection(url).doc(id).onSnapshot(s => {
            let e = s.data().elementPlaying
            let g = s.data().playing
            setIdPlaying(e)
            setEventPlaying(g)
        })
    }

    return (
        <Box sx={{height: '100vh'}}>
            <Stack direction={"column"} justifyContent={"start"} alignContent={"center"} sx={{height: 'fit-content', py: 2}}>
                <Box sx={{height: '3rem'}}></Box>
                {event.data().logo && <LogoBox
                    logo={event.data().logoUrl ? event.data().logoUrl : 'https://storage.googleapis.com/2ndstage-public/logos/logo-2nd.png'}/>}
                {event?.data()?.timer && !!event?.data()?.timer?.startedAt &&
                    <Grow in timeout={2000}>
                        <Box display={'flex'} justifyContent={'center'}>
                            <CountdownTimerWrapper label={event.data().timer.label} initialTime={event.data().timer.duration} isLive={event.data().timer.startedAt}
                                                   startedAt={event.data().timer.startedAt}/>

                        </Box>
                    </Grow>}
                {
                    event.data().autenticazione &&
                    <DisplayName userData={userData} setUserData={setUserData}/>
                }
                {!pinnedLoading && !pinnedError && pinned ?
                    event.data().autenticazione
                        ?
                        (userData.displayName !== '' && userData.displayName) &&
                        <PinnedQuestions questions={pinned}/>
                        :
                        <PinnedQuestions questions={pinned}/>
                    :
                    <></>
                }
                <Button variant={'home-question'}
                        sx={{marginTop:'2vh'}}
                        onClick={() => {
                            navigate('/gallery');
                        }}>
                    SCARICA LE FOTO
                </Button>
            </Stack>
            <Box>
                <iframe style={{borderRadius:'12px'}}
                        src="https://open.spotify.com/embed/playlist/0Zagu8JuJuWRBnwf1tU2SZ?utm_source=generator"
                        width="100%" height="320" frameborder="0" allowtransparency="true" allow="encrypted-media">
                </iframe>

            </Box>
            {loading ?
                <Box sx={{textAlign: 'center'}}>
                    <CircularProgress/>
                </Box>
                :
                event.data().applausometro ?
                    event.data().autenticazione
                        ?
                    (userData.displayName !== '' && userData.displayName) &&
                        <Applausometro url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying} user={user}/>
                    :
                    <Applausometro url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying} user={user}/>
                :
                    <></>
            }
            {(theme && !themeLoading && !themeError && !loading) ?
                event.data().autenticazione
                    ?
                    (userData.displayName !== '' && userData.displayName) &&
                        <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                    :
                    <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                :
                <></>
            }
        </Box>

    )
}
